import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { DEFAULT_CACHE_TIME } from '~/constants'
import { queries } from '~/keys'
import type { SuccessResponse } from '~/types/response'

export type EntitiesTotal = {
  referrals: number
  partners: number
  invoices: number
  total_deals: number
  commissions: number
}

export enum ENTITY_KEYS {
  REFERRALS = 'referrals',
  PARTNERS = 'partners',
  INVOICES = 'invoices',
  TOTAL_DEALS = 'total_deals',
  COMMISSIONS = 'commissions',
}

export const useEntityCount = (entityKey: ENTITY_KEYS, enabled?: boolean) => {
  const { fetch } = useAPIFetch()
  const queryClient = useQueryClient()

  return useQuery({
    ...queries.entities.entity(entityKey),
    queryFn: () =>
      fetch<SuccessResponse<EntitiesTotal>>({
        method: 'GET',
        url: 'totals',
      }),
    enabled,
    staleTime: DEFAULT_CACHE_TIME,
    select: data => {
      const entity = data.data[entityKey]

      // If value is positive, don't need to refetch it anymore
      if (entity) {
        queryClient.setQueryDefaults([queries.entities.entity(entityKey)], {
          staleTime: Infinity,
        })
      }

      return entity
    },
  })
}
